<template>
    <div>
        <div class="book-info">
            <div class="book-title">THE APPARENT SECRET</div>
            <div class="book-subtitle">From Human to Metahuman in <span style="font-weight: bold">90 minutes.</span></div>
        </div>
        <BookRotate/>
        <v-btn
                depressed
                color="primary"
                href="./The Apparent Secret.pdf"
                target="_blank"
        >
            DOWNLOAD PDF <v-icon class="floating-vertical">mdi-download</v-icon>
        </v-btn>
        <div class="num-downloads">10K+ downloads</div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import BookRotate from '../../components/BookRotate.vue';

    @Component({
        components: {
            BookRotate
        }
    })
    export default class Book extends Vue {

    }
</script>

<style scoped lang="scss">
    @import '@/styles/variables.scss';

    .book-info .book-title {
        color: $app-primary;
        font-weight: bold;
        font-size: 18pt;
    }

    .book-info .book-subtitle {
        color: $app-secondary;
        font-weight: 500;
        font-size: 13pt;
        margin: 0 10px;
    }

    .num-downloads {
        margin-top: 5px;
        color: #7d7d7d;
        font-size: 11pt;
    }
</style>
