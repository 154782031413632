import Vue from 'vue';

Vue.mixin({
    data: function () {
        return {
            screenWidth: 0,
            $resizeListener: null
        }
    },
    created() {
        this.$resizeListener = this.onResize.bind(this);
        this.addResizeListener();
        this.$resizeListener();
    },
    destroyed() {

    },
    computed: {
        isMobile() {
            return this.screenWidth <= 1100;
        },
        isIE() {
            const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
            const msie = ua.indexOf('MSIE '); // IE 10 or older
            const trident = ua.indexOf('Trident/'); //IE 11

            return (msie > 0 || trident > 0);
        }
    },
    methods: {
        addResizeListener() {
            window.addEventListener('resize', this.$resizeListener);
        },
        removeResizeListener() {
            window.removeEventListener('resize', this.$resizeListener);
        },
        onResize() {
            this.screenWidth = window.innerWidth;
        }
    }
});

