<template>
    <div style="display: flex; flex-direction: column">
        <div class="top-box">
            <Book />
            <Reviews class="reviews"/>
        </div>
        <author class="author-div"></author>
    </div>
</template>

<script>
    import Book from './components/Book.vue';
    import Reviews from './components/Reviews.vue';
    import Author from './Author';

    export default {
        name: "Home",
        components: {Book, Reviews, Author}
    }
</script>

<style scoped lang="scss">
    @import '@/styles/variables.scss';

    .book-info .title {
        color: $app-primary;
        font-weight: bold;
        font-size: 16pt;
    }

    .book-info .subtitle {
        color: $app-secondary;
        font-weight: 500;
        font-size: 13pt;
    }

    .top-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        background: #ececec;
        padding-bottom: 20px;
        box-shadow: 0 2px 7px 0 #00000038;
    }

    .mobile .top-box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }

    .reviews {
        margin-left: 60px;
    }

    .mobile .reviews {
        margin: 20px 10px 0 10px;
    }

    .author-div{
        margin-top: 10px;
        padding-bottom: 20px;
    }

</style>
