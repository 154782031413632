






































import Vue from 'vue';
import Component from 'vue-class-component';
import reviews from '../../assets/reviews.json';

@Component
export default class Reviews extends Vue {
    currentReview = 0;
    reviews = reviews;
    currentTimeout = 0;

    mounted() {
        this.scheduleNextSlide();
    }

    changeSlide(slideNumber: number) {
        this.currentReview = slideNumber - 1;
        clearTimeout(this.currentTimeout);
        this.scheduleNextSlide();
    }

    next() {
        this.currentReview = this.currentReview + 1 === this.reviews.length
            ? 0
            : this.currentReview + 1
    }

    prev() {
        this.currentReview = this.currentReview - 1 < 0
            ? this.reviews.length - 1
            : this.currentReview - 1
    }

    scheduleNextSlide() {
        this.currentTimeout = setTimeout(() => {
            this.next();
            this.scheduleNextSlide();
        }, 6000);
    }
}

/*export default  {
    name: "Ratings",
    data() {
        return {
            items: [20, 30]
        }
    }

}*/
